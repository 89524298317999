import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Prabin Deka', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://docs.google.com/document/d/13SGXMmF8e5b3P77r8nWEJMcwHECLDPwDeqD9ERoEHLU/edit?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'kenekt.jpg',
    title: 'Director & Co-founder @kenekt',
    info: 'Lead technology at Kenekt',
    info2: '',
    url: 'https://kenekt.com.au',
  },
  {
    id: nanoid(),
    img: 'woolies.jpg',
    title: 'Architect @WooliesX',
    info: 'Instrumental in creating a technology roadmap for the division, and managing architectural transition that the division has embarked on.',
    info2: '',
    url: 'https://www.woolworths.com.au/',
  },
  {
    id: nanoid(),
    img: 'tab.jpg',
    title: 'Team Lead @Tabcorp',
    info: 'Strategy execution and line management responsibility for projects in TAB retail and online. ',
    info2: '',
    url: 'https://www.tab.com.au/racing/meetings/today/R',
  },
  {
    id: nanoid(),
    img: 'thoughtworks.jpg',
    title: 'Senior Consultant @ThoughtWorks',
    info: 'Technology lead in teams of process and technology consultants assessing of current state in organisations. Technical leadership role in mentoring client teams in Agile engineering practices.',
    info2: '',
    url: 'https://www.thoughtworks.com/',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'prabin@kenekt.com.au',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/prabindeka/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/pdeka',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
